import { useState } from "react";

import Schedule from "./schedule";
import ScheduleDateSelector from "./scheduledateselector";

export default function HomeAnnouncedGames({
    announcedDates,
    announcedGameTypes,
    announcedGames,
    onGameChange,
    currentCity,
}) {
    const [gameTypes, setGameTypes] = useState([
        { id: "", name: "Все игры", enrollment_dates: announcedDates.map((dateObj) => dateObj.date) },
        ...announcedGameTypes,
    ]);
    const [selectedDate, setSelectedDate] = useState(0);
    const [selectedGameType, setSelectedGameType] = useState(0);

    const gameType = gameTypes[selectedGameType];
    const date = announcedDates[selectedDate];

    const games = announcedGames.filter((game) => {
        if (gameType.id !== "") {
            return game.game_type.id == gameType.id && game.date == date.date;
        }
        return game.date == date.date;
    });

    const gameTypeSelector = (
        <ScheduleDateSelector
            gameTypes={gameTypes}
            dates={announcedDates}
            selectedGameType={selectedGameType}
            selectedDate={selectedDate}
            city={currentCity}
            onGameTypeSelect={setSelectedGameType}
            onDateSelect={setSelectedDate}
            hideArchiveLink
        />
    );

    return (
        <Schedule
            title="Особенные мероприятия"
            games={games}
            currentCity={currentCity}
            dateSelector={gameTypeSelector}
            showPlacesLeftInfo
            isGamesList={true}
            onGameChange={onGameChange}
        />
    );
}
